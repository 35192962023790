.contact-container {
	width: 100%;
	max-width: 100vw;
	height: 100%;
	display: flex;
}

/******************* CONTACT HEADER ***************/

.contact-header {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 5em auto;
	width: 100%;
}

.contact-title {
	width: 310px;
	height: 120px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.contact-title::before {
	content: '';
	background-image: conic-gradient(
		transparent 180deg,
		var(--color-primary) 90deg
	);
	width: 300%;
	height: 300%;
	position: absolute;
	animation: rotate 5s linear infinite;
}
.contact-title::after {
	content: 'Contact';
	width: 305px;
	height: 115px;
	background: var(--bg-color);
	border-radius: 10px;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: clamp(30px, 2.5vw, 60px);
	font-weight: bold;
}

.dark-mode-title::before {
	background-image: conic-gradient(transparent 180deg, var(--bg-color) 90deg);
}

.dark-mode-title::after {
	background: var(--color-primary);
	color: #fff;
}

@keyframes rotate {
	25% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 500px) {
	.contact-title {
		margin-top: 3em;
		margin-bottom: 2em;
		width: 175px;
		height: 70px;
	}
	.contact-title::after {
		width: 170px;
		height: 65px;
	}
	.contact-title::before {
		width: 500%;
		height: 500%;
	}
	.contact-item {
		gap: 2em;
	}
}

@media only devices and (orientation: landscape) {
	.contact-title {
		margin-top: 3em;
		margin-bottom: 2em;
		width: 175px;
		height: 70px;
	}
	.contact-title::after {
		width: 170px;
		height: 65px;
	}
	.contact-title::before {
		width: 500%;
		height: 500%;
	}
	.contact-item {
		gap: 2em;
	}
}

/********************** LEFT CONTACT SECTION ******************/

.contact-wrapper {
	height: 100%;
	width: 100%;
	max-width: 100vw;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	grid-auto-rows: auto;
}

.left-contact-section {
	display: flex;
	flex: 1;
	height: 90%;
	align-items: center;
	flex-direction: column;
	position: relative;
	border-right: 4px solid var(--color-primary);
}

.left-contact-section h2 {
	font-size: clamp(2em, 2.5vw, 4em);
	margin-bottom: 2em;
	padding-bottom: 10px;
	border-bottom: 4px solid var(--color-primary);
}

.left-contact-section h3 {
	font-size: clamp(1em, 1.5vw, 2.3vmin);
}

.contact-section-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 1em;
	margin-bottom: 1em;
	width: clamp(300px, 80%, 60%);
}

.contact-section-item span {
	border-right: 2px solid var(--color-black);
	padding-right: 1em;
}

.contact-section-item a span {
	width: 50px;
	color: #fff;
	font-size: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease;
}

.contact-section-item a span:hover {
	color: #fff;
}

/********************** RIGHT CONTACT SECTION ******************/

.right-contact-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	width: 100%;
	height: 100%;
}

.contact-box {
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow-y: hidden;
}

.contact-box p {
	width: 90%;
	font-size: clamp(1em, 1.5vw, 2.3vmin);
}

.contact-form {
	padding: 25px 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 90%;
	height: 100%;
	margin-top: 1em;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
	width: 100%;
	margin-bottom: 24px;
	overflow-x: hidden;
}

.contact-form textarea {
	resize: none;
	padding: 1em;
	border: none;
	border-bottom: 2px solid gray;
	outline: none;
	font-family: inherit;
	transition: all 300ms ease;
}

.form-input {
	width: 100%;
	position: relative;
}

.form-input input:focus ~ label,
.form-input input:valid ~ label,
.form-input textarea:focus ~ label,
.form-input textarea:valid ~ label {
	transform: translateY(-30px) scale(0.85);
	left: -0.75%;
	transition: all 500ms ease-in-out;
	color: #fff;
}

.form-input textarea:focus ~ label,
.form-input textarea:valid ~ label {
	left: -1%;
}

.form-input input:valid ~ .underline-1,
.form-input input:valid ~ .underline-2,
.form-input input:valid ~ .underline-3,
.form-input input:valid ~ .underline-4 {
	transform: scale(1);
}

.form-input .underline-1 {
	position: absolute;
	left: 0;
	bottom: 45%;
	height: 3px;
	transform: scaleX(0);
	width: 100%;
	background-color: var(--color-primary);
	transition: all 0.4s ease-in;
}

.form-input .underline-2 {
	position: absolute;
	left: 0;
	bottom: 45%;
	height: 3px;
	transform: scaleX(0);
	width: 100%;
	background-color: var(--color-primary);
	transition: all 0.4s ease-in;
}

.form-input .underline-3 {
	position: absolute;
	left: 0;
	bottom: 45%;
	height: 3px;
	transform: scaleX(0);
	width: 100%;
	background-color: var(--color-primary);
	transition: all 0.4s ease-in;
}

.form-input .underline-4 {
	position: absolute;
	left: 0;
	bottom: 30%;
	height: 3px;
	transform: scaleX(0);
	width: 100%;
	background-color: var(--color-primary);
	transition: all 0.4s ease-in;
}

@-moz-document url-prefix() {
	.form-input .underline-4 {
		bottom: 24%;
	}
}

.form-input input:focus ~ .underline-1,
.form-input input:focus ~ .underline-2,
.form-input input:focus ~ .underline-3,
.form-input textarea:focus ~ .underline-4 {
	transform: scaleX(1);
}

.contact-form input {
	height: 40px;
	padding: 1.2em;
	border: none;
	border-bottom: 2px solid gray;
	outline: none;
	transition: all 500ms ease;
	margin-bottom: 2.5em;
}

.dark-mode-fields {
	background-color: var(--color-black);
	color: #fff;
}

.contact-form label {
	position: absolute;
	left: 1%;
	top: 10%;
	pointer-events: none;
	color: gray;
	font-weight: bold;
}

.contact-form input:focus,
.contact-form textarea:focus {
	background-color: rgba(56, 94, 151, 0.3);
}

.contact-form button {
	padding: 0.8em 1.5em;
	border: 2px solid transparent;
	outline: none;
	font-size: clamp(12px, 2vw, 18px);
	cursor: pointer;
	color: #fff;
	background-color: var(--color-primary);
	font-weight: bold;
	position: relative;
	overflow: hidden;
	transition: all 250ms ease;
}

.contact-form button:hover {
	background-color: transparent;
	border: 2px solid var(--color-primary);
	color: #fff;
}

@media screen and (max-width: 1000px) {
	.left-contact-section {
		border: none;
		margin-bottom: 2em;
	}
}
