.works-container {
	height: 100%;
	min-height: 100vh;
	max-width: 100vw;
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	flex: 1;
}

/******************** WORKS TOP *********************/

.works-top {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 4em;
	height: 100%;
	margin: 5em auto;
}

.works-title {
	width: 400px;
	height: 120px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.works-title::before {
	content: '';
	background-image: conic-gradient(
		transparent 180deg,
		var(--color-primary) 90deg
	);
	width: 400%;
	height: 400%;
	position: absolute;
	animation: rotate 5s linear infinite;
}
.works-title::after {
	content: 'Some Projects';
	width: 395px;
	height: 115px;
	background: var(--bg-color);
	border-radius: 10px;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: clamp(30px, 2.5vw, 60px);
	font-weight: bold;
}

.dark-mode-title::before {
	background-image: conic-gradient(transparent 180deg, var(--bg-color) 90deg);
}

.dark-mode-title::after {
	background: var(--color-primary);
	color: var(--bg-color);
}

.subtitle-dynamic {
	line-height: 50px;
	height: 50px;
	text-align: center;
	font-weight: bold;
	overflow: hidden;
}

.subtitle-dynamic li {
	list-style: none;
	color: var(--color-black);
	font-size: 20px;
	top: 0;
	position: relative;
	animation: slide 20s steps(4) infinite;
}

.subtitle-dynamic li::after {
	content: '';
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: var(--bg-color);
	animation: typewriter 5s steps(38) infinite;
}

.subtitle-dynamic .dark-mode-li::after {
	content: '';
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: var(--color-black);
	animation: typewriter 5s steps(38) infinite;
}

@keyframes typewriter {
	40%,
	60% {
		left: 100%;
	}
	100% {
		left: 0;
	}
}

@keyframes slide {
	100% {
		top: -200px;
	}
}

@media screen and (max-width: 500px) {
	.works-title {
		margin-bottom: 2em;
		width: 260px;
		height: 70px;
	}
	.works-title::after {
		width: 255px;
		height: 65px;
	}
	.works-title::before {
		width: 500%;
		height: 500%;
	}
	.works-item {
		gap: 2em;
	}
}

@media only devices and (orientation: landscape) {
	.works-title {
		margin-bottom: 2em;
		width: 260px;
		height: 70px;
	}
	.works-title::after {
		width: 255px;
		height: 65px;
	}
	.works-title::before {
		width: 500%;
		height: 500%;
	}
	.works-item {
		gap: 2em;
	}
}

/******************** WORKS BOTTOM *********************/

.works-bottom {
	flex: 1;
	min-height: 80vh;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 3em;
}

.works-flex {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 4em;
	height: 100%;
	width: 90%;
	max-width: 800px;
	position: relative;
}

.works-img {
	width: 100%;
	margin: auto;
	flex: 1;
	border-radius: 10px;
	overflow: hidden;
}

.works-img img {
	object-fit: scale-down;
	object-position: center;
	border-radius: 10px;
	width: 100%;
	height: 100%;
}

.works-modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	flex: 1;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	border-radius: 10px;
	transition: all 350ms;
	opacity: 0;
	cursor: pointer;
	border-radius: 10px;
	overflow: hidden;
}

.works-modal:hover {
	opacity: 1;
}

.works-modal-text {
	flex: 2;
	width: 100%;
	padding: 1em 2em;
	text-align: center;
	color: var(--bg-color);
}

.works-modal h3 {
	font-size: clamp(18px, 2.8vw, 25px);
	padding: 1.5em;
}

.works-modal p {
	font-size: clamp(7px, 1.5vw, 12px);
	padding: 0 2em;
	font-weight: bold;
	width: 70%;
	text-overflow: ellipsis;
	margin: auto;
}

.works-icons {
	flex: 1;
	display: flex;
	gap: 2em;
	font-size: 35px;
}

.works-icons i {
	color: var(--color-primary);
}

.works-icons i:hover {
	transform: scale(1.2);
}

@media screen and (max-width: 600px) {
	.works-modal h3 {
		padding: 0.5em;
	}
	.works-modal-text {
		padding-bottom: 0.5em;
	}
	.works-icons {
		margin-top: -12px;
	}
	.works-modal i {
		font-size: 20px;
	}
}
