.wrapper {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	grid-auto-rows: auto;
}

/************************ LEFT BOX *************************/

.left-box {
	flex: 1;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.left-wrapper {
	padding: 50px;
}

.wrapper-intro {
	font-size: clamp(2em, 2.5vw, 4em);
	font-weight: 300;
	color: var(--color-black);
}

h1.wrapper-presentation {
	font-size: clamp(2.25em, 2.75vw, 70px);
	font-weight: bold;
	color: var(--color-black);
}

.wrapper-description {
	margin: 2em 0;
	padding-right: 2em;
	text-align: left;
	font-size: clamp(1em, 1.5vw, 2.3vmin);
	color: var(--color-black);
}

.left-wrapper-title {
	height: 80px;
	overflow: hidden;
}

.wrapper-name {
	height: 100%;
	animation: move 3s ease-in-out infinite alternate-reverse;
}

.wrapper-name-item {
	height: 80px;
	font-weight: bold;
	color: var(--color-primary);
	display: flex;
	align-items: center;
}

.wrapper-name-item span {
	font-size: clamp(2.25em, 2.75vw, 70px);
}

@keyframes move {
	0% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(-95px);
	}
}

@media screen and (max-width: 500px) {
	.wrapper {
		flex-direction: column;
	}
	.left-wrapper {
		padding: 10px;
		text-align: center;
		align-items: center;
		justify-content: center;
		margin-top: 3em;
	}
	.wrapper-description {
		text-align: center;
		padding: 1em;
	}
	.wrapper-name-item {
		justify-content: center;
	}
}

@media only devices and (orientation: landscape) {
	.wrapper {
		margin-bottom: 20vh;
	}
}

/************************ RIGHT BOX *************************/

.right-box {
	flex: 1;
	height: 100%;
	width: 100%;
	padding: 0 2em;
}

.right-box svg {
	width: 100%;
	height: 100%;
}

#right-hand {
	animation: type 500ms ease-in-out infinite alternate;
	transform-origin: center;
}

#left-hand {
	animation: type 500ms ease-in-out infinite alternate-reverse;
	transform-origin: center;
}

#clock-hand {
	animation: clock 60s steps(60) infinite;
	transform-origin: bottom;
	transform-box: fill-box;
}

#hair {
	animation: hair 1s ease-in-out infinite alternate-reverse;
	transform-origin: top;
	transform-box: fill-box;
}

#left-tree {
	animation: tree 1.2s ease-in-out infinite alternate;
	transform-origin: bottom;
	transform-box: fill-box;
}

#right-tree {
	animation: tree 1.2s ease-in-out infinite alternate-reverse;
	transform-origin: bottom;
	transform-box: fill-box;
}

.imgs{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	object-fit: scale-down;

}

@keyframes type {
	from {
		transform: rotateX(0deg);
	}
	to {
		transform: rotateX(15deg);
	}
}

@keyframes clock {
	from {
		transform: rotateZ(0deg);
	}
	to {
		transform: rotateZ(360deg);
	}
}

@keyframes hair {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(1.5%) rotateZ(0.5deg);
	}
}

@keyframes tree {
	from {
		transform: rotateY(0deg) scaleX(1) skewY(0) skewX(0);
	}
	to {
		transform: rotateY(15deg) scaleX(1.1) skewY(4deg) skewX(3deg);
	}
}

/************************ BUTTON *************************/

.cta-btn {
	padding: 1em 1.5em;
	border: none;
	outline: none;
	font-size: clamp(1.25em, 2vw, 2.5vmin);
	cursor: pointer;
	color: #fff;
	background-color: var(--color-primary);
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	transition: all 250ms ease;
}

.cta-btn:hover {
	background-color: var(--bg-color);
	color: var(--color-primary);
}

.cta-btn::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-radius: 10px;
	border-bottom: 3px solid transparent;
	border-left: 3px solid transparent;
	transition: all 0.5s ease;
}

.cta-btn:hover::before {
	width: 100%;
	height: 100%;
	border-color: var(--color-primary);
}

.cta-btn::after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
	height: 0;
	border-radius: 10px;
	border-top: 3px solid transparent;
	border-right: 3px solid transparent;
	transition: all 0.5s ease;
}

.cta-btn:hover::after {
	width: 100%;
	height: 100%;
	border-color: var(--color-primary);
}
