.toggle-wrapper {
	width: 60px;
	height: 30px;
	border-radius: 20px;
	border: 1px solid #999;
	position: fixed;
	top: 15px;
	right: 10px;
	z-index: 999999999999999999;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.toggle-icon {
	width: 20px;
	height: 20px;
}

.toggle-button {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #999;
	position: absolute;
	cursor: pointer;
}
