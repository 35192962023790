:root {
	--bg-color: #001730;
	--color-black: #0e1111;
	--color-primary: #0652dd;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body,
html {
	font-family: 'Open Sans', sans-serif;
	background-color: var(--bg-color);
	scroll-behavior: smooth;
}

.mail-success-container {
	max-width: 100vw;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	display: grid;
	place-items: center;
	background-color: var(--bg-color);
}

.mail-success-img {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mail-success-img img {
	width: 600px;
	height: 600px;
}

.mail-success-text {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.mail-success-text h1 {
	font-size: clamp(1.5em, 2.5vw, 4em);
	padding: 1em;
}

.mail-success-container p {
	font-size: clamp(1em, 1.25vw, 2.5em);
	padding: 1em;
	text-align: center;
}

@media screen and (max-width: 500px) {
	.mail-success-img img {
		width: 300px;
		height: 300px;
	}
}
