.services-container {
	height: 100%;
	max-width: 100vw;
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	flex: 1;
}

/********************** SERVICES TOP ***********************/

.services-top {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 6em auto;
}

.services-title {
	width: 310px;
	height: 120px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.services-title::before {
	content: '';
	background-image: conic-gradient(
		transparent 180deg,
		var(--color-primary) 90deg
	);
	width: 300%;
	height: 300%;
	position: absolute;
	animation: rotate 5s linear infinite;
}

.services-title::after {
	content: 'Services';
	width: 305px;
	height: 115px;
	background: var(--bg-color);
	border-radius: 10px;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: clamp(30px, 2.5vw, 60px);
	font-weight: bold;
}

.dark-mode-title::before {
	background-image: conic-gradient(transparent 180deg, #fff 90deg);
}

.dark-mode-title::after {
	background: var(--color-primary);
	color: #fff;
}

@keyframes rotate {
	25% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 500px) {
	.services-title {
		margin-bottom: 2em;
		width: 175px;
		height: 70px;
	}
	.services-title::after {
		width: 170px;
		height: 65px;
	}
	.services-title::before {
		width: 500%;
		height: 500%;
	}
	.services-item {
		gap: 2em;
	}
}

@media devices and (orientation: landscape) {
	.services-title {
		margin-bottom: 2em;
		width: 175px;
		height: 70px;
	}
	.services-title::after {
		width: 170px;
		height: 65px;
	}
	.services-title::before {
		width: 500%;
		height: 500%;
	}
	.services-item {
		gap: 2em;
	}
}

/********************** SERVICES BOTTOM ***********************/

.services-bottom {
	flex: 2;
	width: 90%;
	height: 100%;
	margin: 0 auto 3em;
	display: grid;
	grid-gap: 1em;
	place-items: center;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	color: #fff;
}

.services-item {
	padding: 1em 2em;
	display: flex;
	max-width: 400px;
	max-height: 400px;
	flex: 1;
	height: 100%;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(0px);
	-webkit-backdrop-filter: blur(0px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	overflow: hidden;
	cursor: pointer;
	transition: all 0.2s ease-in;
	margin-bottom: 5em;
	z-index: 9999;
}

.services-item::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: -200%;
	top: 100%;
	right: 0;
	background-color: var(--color-primary);
	width: 200%;
	height: 200%;
	border-radius: 50%;
	z-index: -999;
}

.services-item:hover {
	transform: scale(1.05);
	color: #fff;
}

.services-item:hover::before {
	top: -50%;
	left: -50%;
	transition: 600ms ease-in-out;
}

div.icon {
	font-size: clamp(30px, 2.5vw, 50px);
	text-align: center;
	padding: 0.5em 0;
	height: 100%;
}

.services-item-title {
	text-align: center;
	font-size: clamp(20px, 2.5vw, 25px);
	height: 100%;
}

.services-item-content {
	text-align: center;
	margin: 1em 0;
	font-size: 20px;
	height: 100%;
	display: flex;
	align-items: flex-end;
}

/********************** SERVICES TECH ***********************/

.services-tech {
	height: 40vh;
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 3em;
	align-items: center;
}

.services-tech-title {
	width: 460px;
	height: 120px;
	border-radius: 10px;
	margin-bottom: 3em;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.services-tech-title::before {
	content: '';
	background-image: conic-gradient(
		transparent 180deg,
		var(--color-primary) 90deg
	);
	width: 400%;
	height: 400%;
	position: absolute;
	animation: rotate 5s linear infinite reverse;
}
.services-tech-title::after {
	content: 'Tech and Tools';
	width: 455px;
	height: 115px;
	background: var(--bg-color);
	border-radius: 10px;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: clamp(30px, 2.5vw, 60px);
	font-weight: bold;
}

.techs-images {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin: 2em auto;
	gap: 2em;
	flex-wrap: wrap;
}

.techs-images img {
	height: 80px;
	width: 100px;
}

@media screen and (max-width: 500px) {
	.services-tech-title {
		margin-bottom: 2em;
		width: 270px;
		height: 70px;
	}
	.services-tech-title::after {
		width: 265px;
		height: 65px;
	}
}

@media only devices and (orientation: landscape) {
	.services-tech-title {
		margin-bottom: 2em;
		width: 270px;
		height: 70px;
	}
	.services-tech-title::after {
		width: 265px;
		height: 65px;
	}
}
