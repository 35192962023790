.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;
	display: flex;
	color: w;
	align-items: center;
	justify-content: space-evenly;
	background: rgb(74,23,227);
	background: linear-gradient(90deg, rgba(74,23,227,1) 0%, rgba(31,161,251,1) 100%);	z-index: 999999;
	height: 100px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.navbar-darkmode {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background-color: var(--bg-color);
	z-index: 999999;
	height: 100px;
	box-shadow: 0 0 20px rgba(255, 255, 255, 0.15);
}

.navbar ul,
.navbar-darkmode ul {
	list-style: none;
}

.navbar a,
.navbar-darkmode a {
	text-decoration: none;
	color: white;
	font-size: clamp(10px, 2vw, 16px);
	padding: 12px;
	border-radius: 10px;
}

.nav-items {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.25em;
	transition: all 0.3s;
	font-weight: bold;
}

.nav-items li {
	position: relative;
	padding: 6px 0;
	letter-spacing: 3px;
}

.nav-items li a::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-radius: 10px;
	border-bottom: 2px solid transparent;
	border-left: 2px solid transparent;
	transition: all 0.3s ease;
}

.nav-items li a:hover::before {
	width: 100%;
	height: 100%;
	border-color: var(--color-primary);
}

.nav-items li a::after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	border-radius: 10px;
	width: 0;
	height: 0;
	border: 2px solid transparent;

	transition: all 0.3s ease;
}

.nav-items li a:hover::after {
	width: 100%;
	height: 100%;
	border-color: #fff;
}

@keyframes fadeRight {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}
